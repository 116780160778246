import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as GeneralActions from '../../store/modules/general/actions';

import { generateToken } from '../../services/trigger/request';

import Animation from '../../components/common/animation';
import { useEffect, useState } from 'react';
import { useFlags } from 'flagsmith/react';

function Marketing({ loading }) {
  const currentCustomer = useSelector(
    (state) => state?.chat?.config?.user?.current_customer_id
  );

  const { enabled, value } = useFlags([
    'enable_marketing_trigger',
  ]).enable_marketing_trigger;

  const [urlIframe, setUrlIframe] = useState('');

  useEffect(() => {
    if (!enabled || !value?.includes(String(currentCustomer))) {
      return (window.location.href = '/home');
    }

    (async () => {
      try {
        const token = await generateToken();

        setUrlIframe(
          `https://disparador.digital/${JSON.parse(value)[0]}/${token}`
        );
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  if (loading || !urlIframe) {
    return <Animation size="full" />;
  }

  return (
    <iframe src={urlIframe} width="100%" height="100%" title="Disparador" />
  );
}

const mapStateToProps = (state) => ({
  currentUser: state?.general?.user,
  loading: state?.general?.user ? false : true,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(GeneralActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Marketing);
